// npm
import React, { memo } from 'react'
import { Box, Container, useTheme } from '@mui/material'
// src
import FixedHeightHeroContainer from '@atoms/hero/FixedHeightHeroContainer'
import SectionText from '@molecules/text/SectionText'
import Layout from '@organisms/global/Layout'
import { createImageSectionTextDefaults } from 'helpers/createImageSectionTextDefaults'
import { CustomSeoTagsProps } from 'helpers/metadata'

interface Props {
  keyId: string
  mainHeading: string
  smallHeading: string
  text?: string | undefined
  children: React.ReactNode
  textAlign: 'left' | 'center'
  metadata: CustomSeoTagsProps
}

const BasicPageLayout = ({
  keyId,
  mainHeading,
  smallHeading,
  text,
  textAlign,
  metadata,
  children,
}: Props) => {
  const theme = useTheme()
  let sectionTextProps = createImageSectionTextDefaults({
    sectionTextVariant: 'centered',
    keyId: keyId,
    mainHeading: mainHeading,
    subtitle: smallHeading,
    text: text,
  })
  // @ts-ignore
  sectionTextProps.textBoxProps = { typography: 'heroText' }
  sectionTextProps.subtitleProps.borderType = 'bottom'
  sectionTextProps.subtitleProps.color = 'primary.text'

  return (
    <Layout
      metadata={metadata}
      headerProps={{
        themeVariant: 'light',
        sizeVariant: 'compact',
        forceSolidNav: true,
      }}
    >
      <FixedHeightHeroContainer bgcolor="primary.light" sizeVariant="compact">
        <Container
          maxWidth={false}
          className="fixed-height"
          sx={{ height: '100%', position: 'relative' }}
        >
          <Box
            className="fixed-height"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 1,
              height: 1,
              py: 16,
            }}
          >
            <SectionText {...sectionTextProps} />
          </Box>
        </Container>
      </FixedHeightHeroContainer>
      <Box
        sx={{
          background: `linear-gradient(180deg, ${theme.palette.primary.light} 0px, ${theme.palette.primary.light} 200px, white 201px, white 100%)`,
        }}
      >
        <Container maxWidth="lg" sx={{ backgroundColor: 'white' }}>
          <Box
            px={{ md: 7, xl: 10 }}
            py={{ xs: 7, xl: 8 }}
            textAlign={textAlign}
          >
            {children}
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default memo(BasicPageLayout)
