import React, { memo } from 'react'
import {
  TextField as TextFieldBase,
  TextFieldProps as TextFieldPropsBase,
} from '@mui/material'
import { Field, FieldProps } from 'formik'

interface TextFieldProps {
  name: string
  label: string
  textFieldProps?: TextFieldPropsBase
}

const TextField = ({ name, label, textFieldProps }: TextFieldProps) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const hasError = meta.touched && meta.error !== undefined

        let shrink: boolean | undefined = undefined
        if (hasError) {
          shrink = true
        }

        return (
          <TextFieldBase
            inputProps={{ ...field, style: { fontSize: '1.4rem' } }}
            label={hasError ? meta.error : label}
            error={hasError}
            {...textFieldProps}
            InputLabelProps={{
              style: { fontSize: '1.4rem' },
              shrink: shrink,
            }}
          />
        )
      }}
    </Field>
  )
}

export default memo(TextField)
