// npm
import React, { memo } from 'react'
import {
  RadioGroup,
  FormControlLabel,
  FormControlLabelProps,
  FormControl,
  FormLabel,
  Box,
  Typography,
  Collapse,
  styled,
} from '@mui/material'
import { useFormikContext } from 'formik'
// src
import RadioButton from '@atoms/inputs/RadioButton'

interface ButtonProps {
  value: string
  label: string
  labelProps?: FormControlLabelProps
}

interface RadioButtonGroupProps {
  keyId: string
  groupLabel: string
  groupName: string
  buttons: ButtonProps[]
}

const Container = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '1px',
  transition: theme.transitions.create(['border-color']),
}))

const RadioButtonGroup = ({
  keyId,
  groupLabel,
  groupName,
  buttons,
}: RadioButtonGroupProps) => {
  const { setFieldValue, values, getFieldMeta } = useFormikContext()

  const meta = getFieldMeta(groupName)
  const hasError = meta.touched && meta.error

  return (
    <Container
      p={{ xs: 4, md: 5, xl: 6 }}
      mt={4}
      borderColor={hasError ? 'error.main' : 'dividerDark'}
    >
      <FormControl component="fieldset" sx={{ margin: 0 }}>
        <FormLabel component="legend">
          <Typography
            variant="h5"
            mb={4}
            color={hasError ? 'error.main' : 'text.primary'}
          >
            {groupLabel}
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-label={groupLabel}
          sx={{ justifyContent: 'center' }}
          name={groupName}
          onChange={(event) => {
            setFieldValue(groupName, event.currentTarget.value)
          }}
        >
          {buttons.map((button: ButtonProps, index) => {
            return (
              <FormControlLabel
                key={`${keyId}-${index}`}
                value={button.value}
                control={<RadioButton />}
                disableTypography
                label={button.label}
                {...button.labelProps}
                sx={{
                  '&:not(:last-child)': {
                    mr: 4,
                  },
                  '&:last-child': {
                    mr: 0,
                  },
                }}
              />
            )
          })}
        </RadioGroup>
      </FormControl>
      <Collapse in={hasError}>
        <Typography
          color="error"
          variant="caption"
          component="div"
          sx={{ pt: 3, whiteSpace: 'normal' }}
        >
          {meta.error}
        </Typography>
      </Collapse>
    </Container>
  )
}

export default memo(RadioButtonGroup)
