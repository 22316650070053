// npm
import React, { memo } from 'react'
import { Box } from '@mui/material'
import { Form as FormikForm, Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'
// src
import Button from '@atoms/buttons/Button'
import TextField from '@atoms/inputs/TextField'
import RadioButtonGroup from '@molecules/inputGroups/RadioButtonGroup'
import { useMessage } from 'context/MessageContext'
import encodeForm from 'helpers/encodeForm'

interface BrochureRequestFromProps {
  keyId: string
  formName: string
  successMessage: string
  errorMessage: string
  buttonLabel: string
}

interface FormValues {
  botField?: string
  firstName: string
  lastName: string
  addressLine1: string
  addressLine2: string
  townCity: string
  postcode: string
  email: string
  phone: string
  designVisit: string
}

const BrochureRequestFrom = ({
  keyId,
  formName,
  successMessage,
  errorMessage,
  buttonLabel,
}: BrochureRequestFromProps) => {
  const { dispatch } = useMessage()

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeForm({ 'form-name': formName, ...values }),
    })
      .then(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'success',
          message: successMessage,
        })
        actions.resetForm()
      })
      .catch(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'error',
          message: errorMessage,
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Formik
      initialValues={{
        botField: '',
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        townCity: '',
        postcode: '',
        email: '',
        phone: '',
        designVisit: '',
      }}
      validationSchema={object().shape({
        firstName: string().required('Please enter your first name'),
        lastName: string().required('Please enter your last name'),
        addressLine1: string().required('Please enter your address'),
        townCity: string().required('Please enter your town/city'),
        postcode: string().required('Please enter your postcode'),
        email: string()
          .email('Please supply a valid email address')
          .required('Email address is Required'),
        phone: string().required('Please enter your telephone number'),
        designVisit: string().required(
          'Please let us know if you require a design visit'
        ),
      })}
      onSubmit={handleSubmit}
    >
      <FormikForm
        name={formName}
        data-netlify={true}
        netlify-honeypot="botField"
        noValidate
      >
        <Box display="none">
          <TextField
            name="botField"
            label="Name"
            textFieldProps={{
              id: 'bot-field',
              type: 'hidden',
            }}
          />
        </Box>
        <TextField
          name="firstName"
          label="First Name"
          textFieldProps={{
            id: 'first-name',
            fullWidth: true,
            required: true,
          }}
        />
        <TextField
          name="lastName"
          label="Surname"
          textFieldProps={{
            id: 'surname',
            fullWidth: true,
            required: true,
          }}
        />
        <TextField
          name="addressLine1"
          label="Address Line 1"
          textFieldProps={{
            id: 'address1',
            fullWidth: true,
            required: true,
          }}
        />
        <TextField
          name="addressLine2"
          label="Address Line 2"
          textFieldProps={{
            id: 'address2',
            fullWidth: true,
          }}
        />
        <TextField
          name="townCity"
          label="Town/City"
          textFieldProps={{
            id: 'town-city',
            fullWidth: true,
            required: true,
          }}
        />
        <TextField
          name="postcode"
          label="Postcode"
          textFieldProps={{
            id: 'postcode',
            fullWidth: true,
            required: true,
          }}
        />
        <TextField
          name="email"
          label="Email Address"
          textFieldProps={{
            id: 'email',
            fullWidth: true,
            required: true,
          }}
        />
        <TextField
          name="phone"
          label="Telephone Number"
          textFieldProps={{
            id: 'phone',
            fullWidth: true,
            required: true,
          }}
        />
        <RadioButtonGroup
          keyId={`${keyId}-design-visit`}
          groupLabel="Would you also like a design visit?"
          groupName="designVisit"
          buttons={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ]}
        />
        <Button type="submit" sx={{ mt: 6 }}>
          {buttonLabel}
        </Button>
      </FormikForm>
    </Formik>
  )
}

export default memo(BrochureRequestFrom)
