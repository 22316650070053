import React, { memo } from 'react'
import { Radio, styled } from '@mui/material'
import { Check } from 'phosphor-react'

const Icon = styled('div')(({ theme }) => ({
  width: 44,
  height: 44,
  background: 'none',
  border: `1px solid ${theme.palette.dividerDark}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  marginRight: '10px',
  [theme.breakpoints.up('sm')]: {
    marginRight: '12px',
  },
  [theme.breakpoints.up('md')]: {
    marginRight: '14px',
  },
  [theme.breakpoints.up('xl')]: {
    marginRight: '16px',
  },
  zIndex: 3,
  svg: {
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
  },
  transition: theme.transitions.create(['background-color']),

  '&.checked, &:hover': {
    borderColor: theme.palette.primary.main,
  },
  '&.checked': {
    borderColor: theme.palette.primary.main,
    svg: {
      opacity: 1,
    },
  },
}))

const RadioButton = ({ ...props }) => {
  return (
    <Radio
      disableRipple
      checkedIcon={
        <Icon className="checked">
          <Check size={20} weight="bold" />
        </Icon>
      }
      icon={
        <Icon>
          <Check size={20} weight="bold" />
        </Icon>
      }
      {...props}
    />
  )
}

export default memo(RadioButton)
