import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'

interface StyleProps {
  sizeVariant?: 'compact'
  isTop?: true
}

export interface FixedHeightHeroContainerProps extends BoxProps, StyleProps {}

const FixedHeightContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTop' && prop !== 'sizeVariant',
})<StyleProps>(({ sizeVariant, isTop, theme }) => ({
  minHeight: '600px',
  marginTop: '86px', // Header compact height
  [theme.breakpoints.up('lg')]: {
    marginTop: '100px',
  },
  ['.fixed-height']: {
    minHeight: '600px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '700px',
    ['.fixed-height']: {
      minHeight: '700px',
    },
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: '800px',
    ['.fixed-height']: {
      minHeight: '800px',
    },
  },
  [theme.breakpoints.up('xl')]: {
    minHeight: '900px',
    ['.fixed-height']: {
      minHeight: '900px',
    },
  },
  ...(sizeVariant === 'compact' && {
    minHeight: '300px !important',
    ['.fixed-height']: {
      minHeight: '300px !important',
    },
  }),
  ...(isTop && {
    marginTop: '0 !important',
  }),
}))

const FixedHeightHeroContainer = ({
  ...props
}: FixedHeightHeroContainerProps) => {
  return <FixedHeightContainer {...props} />
}

export default memo(FixedHeightHeroContainer)
