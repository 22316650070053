// npm
import React, { memo } from 'react'
import { graphql } from 'gatsby'
// src
import Divider from '@atoms/layout/Divider'
import MainHeading from '@molecules/headings/MainHeading'
import BasicPageLayout from 'templates/layouts/BasicPageLayout'
import BrochureRequestForm from '@organisms/forms/BrochureRequestForm'
import DesignVisitForm from '@organisms/forms/DesignVisitForm'

interface Props {
  data: Queries.FormPageQuery
}

const FormPage = ({ data }: Props) => {
  const d = data.datoCmsFormPage

  return (
    <BasicPageLayout
      keyId={d.originalId}
      smallHeading={d.smallHeading}
      mainHeading={d.mainHeadingNode.childMarkdownRemark.html}
      text={d.textNode.childMarkdownRemark.html}
      textAlign="center"
      metadata={{
        seoTags: d.seoMetaTags,
      }}
    >
      <MainHeading
        typographyProps={{
          variant: 'h3',
        }}
        lineBreaksProps={{
          text: d.formHeadingNode.childMarkdownRemark.html,
          keyId: d.originalId,
          breakpoint: 'xs',
        }}
      />
      <Divider display="inline-block" mt={2} mb={{ xs: 2, md: 3, xl: 4 }} />

      {d.form.slug === 'request-a-brochure' && (
        <BrochureRequestForm
          keyId={d.originalId}
          formName={d.form.slug}
          successMessage={d.successMessage}
          errorMessage={d.errorMessage}
          buttonLabel={d.buttonLabel}
        />
      )}
      {d.form.slug === 'design-visit' && (
        <DesignVisitForm
          keyId={d.originalId}
          formName={d.form.slug}
          successMessage={d.successMessage}
          errorMessage={d.errorMessage}
          buttonLabel={d.buttonLabel}
        />
      )}
    </BasicPageLayout>
  )
}

export default memo(FormPage)

export const query = graphql`
  query FormPage($originalId: String!) {
    datoCmsFormPage(originalId: { eq: $originalId }) {
      originalId
      form {
        slug
      }
      mainHeadingNode {
        childMarkdownRemark {
          html
        }
      }
      smallHeading
      textNode {
        childMarkdownRemark {
          html
        }
      }
      buttonLabel
      formHeadingNode {
        childMarkdownRemark {
          html
        }
      }
      successMessage
      errorMessage
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
