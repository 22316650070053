// npm
import React, { memo } from 'react'
import { Box } from '@mui/material'
import { Form as FormikForm, Formik, FormikHelpers } from 'formik'
import { object, string } from 'yup'
// src
import Button from '@atoms/buttons/Button'
import TextField from '@atoms/inputs/TextField'
import { useMessage } from 'context/MessageContext'
import encodeForm from 'helpers/encodeForm'

interface DesignVisitFormProps {
  keyId: string
  formName: string
  successMessage: string
  errorMessage: string
  buttonLabel: string
}

interface FormValues {
  botField?: string
  firstName: string
  lastName: string
  email: string
  phone: string
}

const DesignVisitForm = ({
  keyId,
  formName,
  successMessage,
  errorMessage,
  buttonLabel,
}: DesignVisitFormProps) => {
  const { dispatch } = useMessage()

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeForm({ 'form-name': formName, ...values }),
    })
      .then(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'success',
          message: successMessage,
        })
        actions.resetForm()
      })
      .catch(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'error',
          message: errorMessage,
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Formik
      initialValues={{
        botField: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }}
      validationSchema={object().shape({
        firstName: string().required('Please enter your first name'),
        lastName: string().required('Please enter your last name'),
        email: string()
          .email('Please supply a valid email address')
          .required('Email address is Required'),
        phone: string().required('Please enter your telephone number'),
      })}
      onSubmit={handleSubmit}
    >
      <FormikForm
        name={formName}
        data-netlify={true}
        netlify-honeypot="botField"
        noValidate
      >
        <Box display="none">
          <TextField
            name="botField"
            label="Name"
            textFieldProps={{
              id: 'bot-field',
              type: 'hidden',
            }}
          />
        </Box>
        <TextField
          name="firstName"
          label="First Name"
          textFieldProps={{
            id: 'first-name',
            fullWidth: true,
            required: true,
          }}
        />
        <TextField
          name="lastName"
          label="Surname"
          textFieldProps={{
            id: 'surname',
            fullWidth: true,
            required: true,
          }}
        />
        <TextField
          name="email"
          label="Email Address"
          textFieldProps={{
            id: 'email',
            fullWidth: true,
            required: true,
          }}
        />
        <TextField
          name="phone"
          label="Telephone Number"
          textFieldProps={{
            id: 'phone',
            fullWidth: true,
            required: true,
          }}
        />
        <Button type="submit" sx={{ mt: 6 }}>
          {buttonLabel}
        </Button>
      </FormikForm>
    </Formik>
  )
}

export default memo(DesignVisitForm)
